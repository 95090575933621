import React from 'react'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'

import {
  ContainerSVGT,
  MainFont,
  HomeColor,
} from '../components/Sampler'

const NotFoundPage = () => (
  <Layout>
    <div
      className="full-width-image margin-top-0"
      style={{backgroundColor: 'white'}}
    >

      {/* Google Fonts Import */}
      <link
        href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>

      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          width: ''
        }}
      >
        {/* BLANK WHITE CIRCLE SVG */}
        <Link to="/" title="Home">
          <div style={{ height: '300px' }}>
            <div style={{
              position: 'absolute',
              marginTop: '72px',
              width: '100%',
              left: '0px',
              textAlign: 'center',
              fontSize: '3.4em',
              color: 'white',
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
              fontWeight: MainFont === 'Maven Pro' ? 'bold' : null
            }}>
              Your<br />Church<br />Name
            </div>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="310px" viewBox="0 0 1563.000000 1525.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                fill={HomeColor} stroke="none">
                <path d={ContainerSVGT} />
              </g>
            </svg>
          </div>
        </Link>
      </div>
    </div>
    <div>
      <h1 style={{marginLeft: '-10px', fontFamily: MainFont, fontSize: '60px', textAlign: 'center'}}>...Oops.</h1>
      <p style={{ marginTop: '20px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>You tried to go to a page<br></br>in our site that doesn't exist.</p>
      <p style={{ marginTop: '20px', marginBottom: '50px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>To return to our home page,<br></br>click the banner above.</p>
    </div>
  </Layout>
)

export default NotFoundPage
